<template>
    <section id="contact" data-nav="header--dark">
        <component is="Parallaxy" :speed="50" direction="opposite" class="container pb-36">
            <div class="flex flex-row">
                <div class="basis-3/4 md:basis-full">
                    <AnimatedHeader text="Contact." />
                    <div>
                        <div>
                            <h3 class="font-300 mb-0">
                                Interested in our services or have a question? Call us or fill out the form and we will get back to you as soon as possible.
                            </h3>
                        </div>
                        <div class="flex flex-row mt-24 sm:flex-col">
                            <div class="basis-1/3 sm:basis-full">
                                <a 
                                    href="tel:+48793035895"
                                    aria-label="Global: +48 793 035 895"
                                    class="text-5 flex flex-wrap justify-between"    
                                >
                                    <span class="font-700">Global: </span>
                                    +48 793 035 895
                                </a>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="basis-2/3 sm:basis-full">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </component>
    </section>
</template>
<script>
import AnimatedHeader from './AnimatedHeader.vue';
import ContactForm from './ContactForm.vue';
import Parallaxy from '@lucien144/vue3-parallaxy'
export default {
    name: 'Contact',
    components: {
        AnimatedHeader,
        ContactForm,
        Parallaxy
    },    
};
</script>