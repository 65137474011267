<template>
	<div class="icon" v-html="icon"></div>
</template>

<script>
import DOMPurify from 'dompurify';
	export default {
		name: 'Icon',
		props: {
			name: {
				type: String,
				required: true,
			},
		},
		data(){
			return {
				icon: null
			}
		},
		watch: {
			name: {
				async handler(name){
					const icon = await import(`@/assets/icons/${name}.js`);
					this.icon = icon?.default ? DOMPurify.sanitize(icon.default) : null;
				},
				immediate: true
			}
		}
	}
</script>