import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Home from "./routes/Home.vue";
import Unsubscribe from "./routes/Unsubscribe.vue";
import App from './App.vue';
import directives from "./js/directives";
import GButton from "./global/GButton.vue";
import GIcon from "./global/GIcon.vue";
import GSpinner from "./global/GSpinner.vue";
import GLogo from "./global/GLogo.vue";
import './assets/scss/main.scss';
import './index.css';

const Vue = createApp(App);

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: 'Home',
            path: '/',
            component: Home 
        },
        {
            name: 'Unsubscribe',
            path: '/unsubscribe',
            component: Unsubscribe,
            beforeEnter: (to, from, next) => {
                if (to.query.email) {
                    next();
                } else {
                    next({ name: 'Home' })
                }
            }
        }
    ],
    scrollBehavior(to, from, savedPosition){
        if (to.hash){
            setTimeout(() => {
                let target = document.querySelector(to.hash);
                let header = document.querySelector('.header');
                const headerOffset = header.getBoundingClientRect().height;
                const targetPosition = target.getBoundingClientRect().top;
                const offsetPosition = targetPosition + window.scrollY - headerOffset;
    
                window.scrollTo({
                    top: (offsetPosition - (100 / 1.6)),
                    behavior: 'smooth'
                });
            }, 100);
        }

        const top  = savedPosition ? savedPosition.top : null;

        window.scrollTo({
            top: top ? top : 0,
            behavior: 'smooth'
        });
    }
});
  
Vue.use(router);
// Global components
Vue.component("GButton", GButton);
Vue.component("GSpinner", GSpinner);
Vue.component("GIcon", GIcon);
Vue.component("GLogo", GLogo);

// Directives register
directives.forEach(directive => Vue.directive(directive.name, directive.config));

Vue.mount('#app');
