<template>
    <header 
        ref="header" 
        class="header" 
        :class="[headerCls, { 'shiftUp': shiftUp }]"
    >
        <div class="header__container">
            <teleport to="#app">
                <div class="container overflow-hidden">
                    <router-link to="/">
                        <GLogo 
                            class="logo--header" 
                            :class="{ 'logo--alt': menuOpen }" 
                            :init-animation="true"
                            :mobile-version="true"
                        />
                    </router-link>
                </div>
            </teleport>
            <Nav 
                :links="links" 
                v-model:scroll-position="scrollPosition"
                v-model:header-cls="headerCls"
                v-model:menu-open="menuOpen"
            />            
        </div>
    </header>
    <Hero ref="hero" :unsubscribe="unsubscribe" />
</template>

<script>   
    import Hero from "./Hero.vue";
    import Nav from "./Nav.vue";
    export default {
        name: 'Header',
        props: {
            allowShiftUp: {
                type: Boolean,
                default: false,
            },
            unsubscribe: {
                type: Object,
                default: null
            }
        },
        components: {
            Hero,
            Nav
        },
        data() {
            return {

                headerCls: null,
                scrollPosition: 0,
                shiftUp: false,
                menuOpen: false,
                links: [
                    {
                        hash: "#about",
                        label: 'About us',
                    },
                    {
                        hash: "#services",
                        label: 'Services',
                    },
                    {
                        hash: "#portfolio",
                        label: 'Portfolio',
                    },
                    {
                        hash: "#contact",
                        label: 'Contact',
                    },
                ]
            }
        },
        watch: {
            scrollPosition(curr, prev){
                if (this.allowShiftUp){
                    this.handleShiftUp(curr, prev)
                }
            }
        },
        methods: {
            handleShiftUp(curr, prev){
                const heroHeight = this.$refs.hero.$el.getBoundingClientRect().height;
                this.shiftUp = curr > prev && curr > heroHeight;
            }
        },
    };
</script>