<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__content">
                <div class="flex md:flex-col items-center md:items-start flex-wrap">
                    <router-link to="/">
                        <GLogo class="logo--footer md:self-start"/>
                    </router-link>
                    <div class="flex grow md:flex-col items-center md:items-start sm:w-full sm:items-stretch">
                        <nav class="footer__links md:pl-0 sm:mt-6">
                            <router-link 
                                :to="{ name: 'Home', hash: '#about' }"
                                class="footer__link body-sm"
                                aria-label="About section"
                                >
                                About
                            </router-link>
                            <router-link 
                                :to="{ name: 'Home', hash: '#services' }"
                                class="footer__link body-sm"
                                aria-label="Services section"
                                >
                                Services
                            </router-link>
                            <router-link 
                                :to="{ name: 'Home', hash: '#portfolio' }"
                                class="footer__link body-sm"
                                aria-label="Portfolio section"
                            >
                                Portfolio
                            </router-link>
                            <router-link 
                                :to="{ name: 'Home', hash: '#contact' }"
                                class="footer__link body-sm"
                                aria-label="Contact section"
                            >
                                Contact
                            </router-link>
                        </nav>
                        <div class="footer__location ml-auto md:ml-0 md:mt-6 md:grow sm:mt-12">
                            <p class="body-sm">Located in Poland (CET)</p>
                        </div>
                        <div class="footer__contact md:ml-0 md:mt-6">
                            <a 
                                href="tel:+48793035895"
                                aria-label="Global: +48 793 035 895"
                                class="body-sm flex flex-wrap justify-between color-0"    
                            >
                                <span class="font-400">Global: </span>
                                +48 793 035 895
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>