<template>
    <div 
        class="logo" 
        :class="{
            'animate': initAnimation && animate,
            'animated': animationEnd,
            'logo--animation': initAnimation
        }"
        @animationend="animationEnd = true"
    >
        <GIcon class="logo__icon" name="logoHorz"/>
        <GIcon v-if="mobileVersion" class="logo__icon--mobile" name="logoCircle" />
    </div>
</template>
<script>
export default {
    name: 'Logo',
    props: {
        initAnimation: {
            type: Boolean,
            default: false,
        },
        mobileVersion: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            animate: false,
            animationEnd: false
        };
    },
    mounted(){
        if (this.initAnimation){
            setTimeout(() => {
                this.animate = true;
            }, 200);
        }
    }
}
</script>