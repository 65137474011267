<template>
    <div class="hero">
        <div class="hero__image">
            <picture>
                <source
                    srcset="@/assets/content/images/hero-mobile.jpg"
                    media="(max-width: 767px)"
                >
                <source
                    srcset="@/assets/content/images/hero-desktop.jpg"
                    media="(max-width: 1023px)"
                >
                <img src="@/assets/content/images/hero-desktop.jpg" alt="Hero image.">
            </picture>
        </div>
        <div class="container relative z-10 ">
            <div 
                class="hero__container"
                @mouseover="pause"
                @mouseout="play"
            >
                <transition name="header" mode="out-in" appear>
                    <h1 
                        class="color-0" 
                        :key="headerIndex && unsubscribe?.message" 
                    >
                        {{ header.header }}

                        <div v-if="unsubscribe?.processing" class="hero__unsubscribe">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </div>
                    </h1>
                </transition>
                <transition name="header" mode="out-in" appear>
                    <h2 
                        class="text-4 color-0" 
                        :style="`transition-delay: 100ms`"
                        v-html="header.subheader" 
                        :key="headerIndex" 
                    />
                </transition>
                <transition name="header" mode="out-in" appear>
                    <GButton 
                        class="self-start mt-12"
                        @click="handleClick"
                        :key="headerIndex"
                        :style="`transition-delay: 200ms`"
                    >
                        {{ header.button }}
                    </GButton>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Hero',
    props: {
        unsubscribe: {
            type: Object,
            default: null,
        }
    },
    data(){
        return {
            headerIndex: 1,
            interval: 7500,
            intervalId: null,
            headers: [
                {
                    header: 'Craft Your Vision with Our Code',
                    subheader: 'Contract Expertise and Full-Time Dev Power at Your Fingertip',
                    button: 'Send your inquiry'
                },
                {
                    header: 'Mid-level Frontend Engineers',
                    subheader: 'Available Now! <br>Full-time / Long-term contract <br>[ JavaScript / Vue.js, 2.5yrs+ ]',
                    button: 'Send your inquiry'
                }
            ],
            unsubscribeHeaders: {
                header: 'You unsubscribed successfully.',
                subheader: 'Feel free to explore our website for more updates and insights.',
                button: 'Home page'
            }
        };
    },
    computed: {
        header(){
            if (this.$route.name === 'Unsubscribe') {
                if (this.unsubscribe){
                    if(this.unsubscribe.processing){
                        this.unsubscribeHeaders.header = 'Unsubscribing'
                        return this.unsubscribeHeaders;
                    } else {
                        this.unsubscribeHeaders.header = this.unsubscribe.message;
                        return this.unsubscribeHeaders;
                    }
                }

            }
            return this.headers[this.headerIndex];
        }
    },
    watch: {
        unsubscribe: {
            handler(obj){
                console.log(obj);
            },
            deep: true,
        },
    },
    methods: {
        handleClick(){
            if (this.$route.name === 'Unsubscribe') {
                this.$router.push('/');
            } else {
                this.$router.push({
                    name: 'Home',
                    hash: '#contact'
                })
            }
        },
        updateHeader() {
            if (this.$route.name === 'Unsubscribe') return;
            this.headerIndex = (this.headerIndex + 1) % this.headers.length;
        },
        pause(){
            clearInterval(this.intervalId);
        },
        play(){
            this.intervalId = setInterval(this.updateHeader, this.interval);
        }
    },
    mounted() {
        this.play();
    },
    beforeDestroy() {
        this.pause();
    }
}
</script>