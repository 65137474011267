<template>
    <section 
        id="services" 
        data-nav="header--dark" 
        class="pb-80 sm:pb-60"
    >
    <component is="Parallaxy" :speed="50" direction="opposite" class="container pb-36 sm:pb-16">
        <div class="flex flex-row">
            <div class="basis-3/4 sm:basis-full">
                    <AnimatedHeader text="Services." />
                    <div>
                        <h3 class="font-300">
                            Flexible Engagement, Unwavering Quality
                        </h3>
                        <p class="body mt-12">
                            At Everse, we offer a spectrum of services that assure not only flexibility in engagement but also a steadfast commitment to quality. Our offerings are designed to align with the varying dynamics of your business needs, whether it's for a short-term sprint or a marathon of ongoing development.
                        </p>
                    </div>
                    <div class="mt-12">
                        <div>
                            <h4 class="font-400 my-0">
                                Contract Development
                            </h4>
                            <p class="body mt-6">
                                Our contract development service is the perfect solution for projects that need a specific set of skills or a temporary boost in manpower. We provide elite developers who can dive into your project at any stage, bringing with them a wealth of expertise in various technologies and industries. They're not just temporary resources; they're strategic assets who will work diligently to ensure your project's success, adding value with their high-caliber skills and agile adaptability.
                            </p>
                        </div>
                        <div class="mt-12">
                            <h4 class="font-400 my-0">
                                Full-Time Development
                            </h4>
                            <p class="body mt-6">
                                When continuity and deep integration matter, our full-time development service stands out. Our developers become an extension of your team, sharing your vision and commitment. They're in it for the long haul, from the initial brainstorming sessions to the final stages of project deployment and beyond. With a focus on collaboration and innovation, they help drive your projects forward, ensuring each milestone is met with precision and each goal is achieved with excellence.
                            </p>
                        </div>
                        <div class="mt-12">
                            <h4 class="font-400 my-0">
                                Support & Maintenance
                            </h4>
                            <p class="body mt-6">
                                Beyond the launch lies the critical phase of support and maintenance, a service we hold in high regard. Our approach goes beyond fixing bugs; we focus on proactive optimization to ensure your digital assets continue to perform at their best. From routine updates to performance tuning and security enhancements, our team is on hand to provide continuous care. This service is about keeping your platforms not just running, but also evolving with your business and the tech landscape, ensuring long-term resilience and relevance.
                            </p>
                        </div>
                        <p class="body font-500 mt-6">
                            Each of our services is underpinned by a commitment to building strong, enduring partnerships with our clients. With eVerse, you gain more than just development expertise; you gain a dedicated ally in your journey towards digital excellence.
                        </p>
                    </div>
                </div>

            </div>
        </component>
    </section>    
</template>
<script>
import AnimatedHeader from './AnimatedHeader.vue';
import Parallaxy from "@lucien144/vue3-parallaxy"
export default {
    name: 'Services',
    components: {
        AnimatedHeader,
        Parallaxy
    }
};
</script>