<template>
	<Header :unsubscribe="unsubscribe" />
		<router-view @updateHeroMessage="unsubscribe = $event"/>
	<Footer />
	
	<transition name="notification" appear>
		<Notification 
			v-if="notification"
			:notification="notification"
			:duration="notificationDuration"
		/>
	</transition>
</template>

<script>
import {
	onNotificationChange
} from "@/js/notification";
import Notification from "./components/Notification.vue";
import Header from "./components/Header.vue";
	import Footer from "./components/Footer.vue";

export default {
	name: 'App',
	components: {
		Header,
		Footer,
		Notification,
	},
	data() {
		return {
			notification: null,
			notificationDuration: 5000,
			unsubscribe: null
		};
	},
	created() {
		this.detach = onNotificationChange(notification => {
			this.notification = notification;
			// Optionally, clear the notification after a delay
			setTimeout(() => this.notification = null, this.notificationDuration);
		});
	},
	beforeDestroy() {
		if (this.detach) {
			this.detach();
		}
	}
};
</script>