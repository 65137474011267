<template>
    <component 
        ref="header"
        :is="tag" 
        class="animatedHeader" 
        v-view:cb="$event => inView = $event"
        :style="`top: -${height}px; margin-bottom:-${height}px`"
    >
        <div class="animatedHeader__inner">
            <span 
                v-for="(word, i) in splitText" 
                :key="i"
            >
                <span 
                    class="animatedHeader__letter" 
                    :class="{ 'active': getPosition(i, j) < current }"
                    v-for="(letter, j) in word" 
                    :key="j" 
                    v-html="letter" 
                />
            </span>
        </div>
    </component>
</template>

<script>
import { throttle } from "lodash";
    export default {
        name: 'AnimatedHeader',
        props: {
            text: {
                type: String,
                default: 'Lorem ipsum.'
            },
            tag: {
                type: String,
                default: 'h2'
            }
        },
        data() {
            return {
                current: 0,
                inView: false,
                intervalId: null,
                height: undefined,
                throttledResize: null,
            }
        },
        computed: {
            splitText() {
                const wordsAndPunctuation = this.text.split(/(\s+|\b)/);
                const arrayOfLetters = wordsAndPunctuation.map((element) => {
                    if (element === ' ') {
                        return ['&nbsp;']; // Replacing space with HTML entity fixes inline-block width collapse
                    }
                    return element.split('');
                });
                return arrayOfLetters.filter((arr) => arr.length > 0);
            },
            introLength() {
                return this.text.length;
            },
        },
        watch: {
            inView: {
                handler(is) {
                    if (is) {
                        this.startAnimation(50);
                    } else {
                        this.current = 0
                    }
                },
                immediate: true,
            }
        },
        methods: {
            getPosition(i, j) {
                let position = 0;
                for (let index = 0; index < i; index++) {
                    position += this.splitText[index].length;
                }
                position += j;
                return position;
            },
            startAnimation(interval) {
                this.intervalId = setInterval(() => {
                    if (this.current < this.introLength) {
                        this.current++
                    } else {
                        clearInterval(this.intervalId);
                    }
                }, interval);
            }
        },
        mounted(){
            this.throttledResize = throttle(() => {
                this.height = this.$refs.header.getBoundingClientRect().height;
            }, 100)

            window.addEventListener("resize", this.throttledResize);

            this.$nextTick(() => {
                this.height = this.$refs.header.getBoundingClientRect().height;
            })
        },
    }
</script>