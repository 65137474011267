<template>
    <div class="lightbox">
        <p>We take client confidentiality seriously, and due to privacy agreements, we are unable to showcase the majority of our projects publicly. Below, however, you will find a curated selection of work that we have been permitted to share. We are proud of the work we do and grateful for the trust placed in us by all of our clients.</p>
        <div v-if="lightbox" class="lightbox__elements">  
            <a 
                v-for="(mediaEl, i) in lightbox.elements"
                :key="i"
                :href="mediaEl.href"
                @click.prevent="lightbox.openAt(i)"
                class="lightbox__thumbnail"
                :aria-label="`Project overview ${mediaEl.type}`"
            >
                <img 
                    :src="mediaEl.poster ? mediaEl.poster : mediaEl.href"
                    alt="" 
                />
                
                <span
                    v-if="mediaEl.poster" 
                    class="lightbox__label" 
                    v-text="'Video'"
                />
            </a>
        </div>
    </div>
</template>

<script>
import GLightbox from "glightbox";

export default {
    name: 'Lightbox',
    data(){
        return {
            lightbox: null
        };
    },
    computed: {
        assets(){
            let images = [];
            let videos = [
                {
                    src: require('@/assets/content/videos/Avalere.mp4'),
                    type: 'video',
                    poster: require('@/assets/content/images/Avalere_poster.jpg')
                },
                {
                    src: require('@/assets/content/videos/Avalere_mobile.mp4'),
                    type: 'video',
                    poster: require('@/assets/content/images/Avalere_mobile_poster.jpg')
                },
                {
                    src: require('@/assets/content/videos/Fishawack_Health.mp4'),
                    type: 'video',
                    poster: require('@/assets/content/images/Fishawack_poster.jpg')
                },
                {
                    src: require('@/assets/content/videos/Expert_Partnership.mp4'),
                    type: 'video',
                    poster: require('@/assets/content/images/Expert_partnership_poster.jpg')
                },
                {
                    src: require('@/assets/content/videos/Ryeqo.mp4'),
                    type: 'video',
                    poster: require('@/assets/content/images/Ryego_poster.jpg')
                },
                {
                    src: require('@/assets/content/videos/Ryeqo_mobile.mp4'),
                    type: 'video',
                    poster: require('@/assets/content/images/Ryeqo_mobile_poster.jpg')
                },
                {
                    src: require('@/assets/content/videos/Reach_For_Heart.mp4'),
                    type: 'video',
                    poster: require('@/assets/content/images/Reach_for_heart_poster.jpg')
                },
            ]

            for (let i = 1; i < 6; i++) {
               images.push({
                    src: require(`@/assets/content/images/project-${i}.jpg`),
                    type: 'image'
               })
            }

            return [...videos, ...images];
        }
    },
    async mounted(){     
        const elements = this.assets.map(asset => ({
            href: asset.src,
            type: asset.type,
            poster: asset.poster
        }));

        this.lightbox = GLightbox({ 
            elements,
        })
    }
}
</script>