<template>
    <nav class="navigation">
        <ul class="navigation__links">
            <li 
                :ref="(el) => links[i].el = el" 
                class="navigation__link" 
                v-for="(link, i) in links" 
                :key="i"
                @click="scrollTo(link, i)" 
                @mouseover="setActiveState(i)" 
                @mouseout="isInView"
            >
                <span class="navigation__link--inner">
                    {{ link.label }}
                </span>
            </li>
        </ul>
        <div class="navigation__underline" :style="{ 'left': lineLeft + 'px' }" :class="{ 'active': lineWidth }">
            <div class="navigation__underline__visible" :style="{ 'width': lineWidth + 'px' }"></div>
        </div>
    </nav>


    <teleport to="#app">
        <transition 
            name="mobile-nav" 
            appear
        >
            <div v-if="menuOpen" class="navigation--mobile">
                <!-- <div class="navigation__title">
                    <span>M</span>
                    <span>enu</span>
                </div> -->
                <ul class="navigation__links">
                    <li
                        :ref="(el) => links[i].el = el" 
                        class="navigation__link" 
                        v-for="(link, i) in links" 
                        :key="i"
                        @click="handleMobileClick(link, i)" 
                        :class="{'active': i === index}"
                    >
                        <span 
                            class="navigation__link--inner" 
                            v-html="link.label"
                        />
                    </li>
                </ul>
            </div>
        </transition>

        <div 
            class="navigation__burger" 
            :class="{ 'active': menuOpen }"
            @click="handleClose"
        >   
            <svg viewBox="25 25 50 50" xmlns="http://www.w3.org/2000/svg">
                <path class="line--1" d="M0 40h62c13 0 6 28-4 18L35 35" />
                <path class="line--2" d="M0 50h70" />
                <path class="line--3" d="M0 60h62c13 0 6-28-4-18L35 65" />
            </svg>
        </div>        
    </teleport>
</template> 
<script>
 import { throttle } from "lodash";
export default {
    name: 'Nav',
    emits: ['update:scrollPosition', 'update:headerCls', 'update:menuOpen'],
    props: {
        links: {
            type: Array,
            required: true,
        },
        scrollPosition: {
            type: Number,
            default: 0,
        },
        headerCls: {
            type: String,
            default: null
        },
        menuOpen: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            index: null,
            lineLeft: 0,
            lineWidth: 0,
        };
    },
    watch: {
        menuOpen(open){
            const body = document.querySelector('body');
            
            if (open){
                body.classList.add('overflow-hidden');
            } else {
                body.classList.remove('overflow-hidden');
            }
        }
    },
    methods: {
        scrollTo(link, i) {
            this.index = i;

            this.$router.push({
                name: 'Home',
                hash: link.hash
            });
        },
        isInView() {
            this.$emit('update:scrollPosition', window.scrollY);

            this.$nextTick(() => {
                const heroHeight = document.querySelector('.hero')?.getBoundingClientRect().height || 0;

                for (let i = 0; i < this.links.length; i++) {
                    const target = document.querySelector(this.links[i].hash);
                    if (!target) continue;

                    const targetRect = target.getBoundingClientRect();
                    const animatedHeaderHeight = target.querySelector('.animatedHeader')?.getBoundingClientRect().height || 0;
                    const effectiveTargetTop = targetRect.top + window.scrollY - animatedHeaderHeight;
                    const effectiveTargetBottom = effectiveTargetTop + targetRect.height;

                    const isWithinView = window.scrollY > effectiveTargetTop - 75 && window.scrollY < effectiveTargetBottom;
                    const isPastHero = window.scrollY > heroHeight - 170;

                    if (isPastHero && isWithinView) {
                        this.index = i;
                        if (!this.menuOpen) {
                            this.$emit('update:headerCls', target.getAttribute('data-nav'));
                        }
                        this.setActiveState(i);
                    } else if (!isPastHero) {
                        this.resetState();
                    }
                }
            });
        },

        resetState() {
            this.index = null;
            this.lineWidth = 0;
            this.lineLeft = 0;
            this.$emit('update:headerCls', null);
        },
        setActiveState(index) {
            const currentLinkEl = this.links[index].el.children[0];
            this.lineWidth = currentLinkEl.getBoundingClientRect().width;
            this.lineLeft = currentLinkEl.getBoundingClientRect().x;
        },
        handleMobileClick(link, i){
            this.$emit('update:menuOpen', false);

            setTimeout(() => {
                this.scrollTo(link, i)
            }, 300);

        },
        handleClose(){
            if (this.menuOpen){
                this.isInView();
            }

            let open = !this.menuOpen;

            this.$emit('update:menuOpen', open);
        }
    },        
    mounted() {
        this.throttledIsInView = throttle(this.isInView, 500);

        window.addEventListener('scroll', this.throttledIsInView);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.throttledIsInView);
        this.throttledIsInView.cancel();
    },
}
</script>