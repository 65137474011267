<template>
    <div class="container"></div>
</template>

<script>
import axios from "axios";
export default {
    name: 'Unsubscribe',
    data(){
        return {
            unsubscribeEmail: null,
            endpoint: 'https://everse.eu/unsubscribe.php',
            processing: false,
            message: null,
        };
    },
    watch: {
        processing: {
            handler(){
                this.$emit('updateHeroMessage', {
                    message: this.message,
                    processing: this.processing
                });
            },
            immediate: true,
        }
    },
    methods: {
        async addEmailToUnsubscribe() {
        this.processing = true;

        try {
            const response = await axios.post(this.endpoint, new URLSearchParams({
                email: this.unsubscribeEmail
            }).toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            this.message = response.data;
        } catch (error) {
            this.message = error.response ? error.response.data : 'An error occurred';
            console.error(error);
        } finally {
            this.processing = false;
        }
    }
    },
    mounted(){
        this.unsubscribeEmail = this.$route.query.email ?? null
        
        if (this.unsubscribeEmail){
            this.addEmailToUnsubscribe();
        }

    }
}
</script>