<template>
    <button 
        ref="button"
        class="button button--primary"
        :class="[
            { 
                'loading': status === 'loading', 
                'success': status === 'success',
                'failed': status === 'failed',
                'disabled': disabled
            }
        ]"
        :disabled="disabled"
        :type="type"
        @mouseenter="handleHover"
        @click="$emit('click')" 
        @mouseleave="handleHover"
    >
        <div v-if="hasLeftIcon" class="button__icon button__iconLeft">
            <slot name="icon-left"></slot>
        </div>
        <div v-if="hasRightIcon" class="button__icon button__iconRight">
            <slot name="icon-right"></slot>
        </div>
        <div class="button__label">
            <slot></slot>
        </div>
        <slot name="spinner"></slot>
        <span class="button__jsHover" :style="`left: ${left}px; top: ${top}px`"></span>
    </button>
</template>

<script>
    export default {
        name: 'Button',
        emits: ['click'],
        props: {
            secondary: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: null,
            },
            status: {
                type: String,
                default: null
            },
            to: {
                type: [String, Object],
                default: null,
            }
        },
        data(){
            return {
                top: 0,
                left: 0,
            };
        },
        computed: {
            hasLeftIcon(){
                return this.$slots['icon-left']
            },
            hasRightIcon(){
                return this.$slots['icon-right']
            },
        },
        methods: {
            handleHover(e){
                const { left, top } = this.$refs.button.getBoundingClientRect();
                this.left = e.clientX - left;
                this.top = e.clientY - top;
            },
        },
    }
</script>