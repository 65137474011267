<template>
	<div class="spinner" :class="`spinner--${status}`">
		<GIcon name="spinner" />
	</div>
</template>
<script>
export default {
    props: {
        status: {
            type: String,
            default: 'loading',
        },
    }
};
</script>
