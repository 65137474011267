<template>
    <form 
        @submit.prevent="handleSubmit"
        class="form"
    >
        <FormInput 
            v-model:model-value="form.name" 
            label="Name" 
            id="name"
            :error="v$.form.name"
        />
        <FormInput 
            v-model:model-value="form.telephone" 
            label="Telephone" 
            id="telephone" 
            :error="v$.form.telephone"
            :required="false"
        />
        <FormInput 
            v-model:model-value="form.email" 
            label="Email address" 
            id="email" 
            :error="v$.form.email"
        />
        <FormInput
            component="textarea"
            v-model:model-value="form.message"
            :error="v$.form.message"
            label="Message"
            id="message"
            cols="30" 
            rows="10"
        />

        <!-- Honey pot -->
        <input
            class="honeyInput" 
            type="text" 
            v-model="form.honey"
            tabindex="-1"
            autocomplete="false"
        >

        <GButton 
            class="mt-12" 
            type="submit" 
            :status="status"
            :disabled="status === 'loading'"
        >
            <transition name="fade" :key="status">
                <span v-if="status === 'success'" v-text="'Success'"/>
                <span v-else-if="status === 'failed'" v-text="'Retry'"/>
                <span v-else v-text="'Submit '"/>
            </transition>
            <template #spinner>
                <GSpinner 
                    class="spinner--button" 
                    :class="{ 'active': status }"    
                    :status="status" 
                />
            </template>
        </GButton>
    </form>
</template>

<script>
import axios from "axios";
import FormInput from "./FormInput.vue";
import useVuelidate from "@vuelidate/core";
import {required, numeric, email, helpers} from "@vuelidate/validators";
import { notify } from "@/js/notification";

export default {
    name: "ContactForm",
    components: {
        FormInput,
    },
    setup () {
        return { v$: useVuelidate()  }
    },
    data(){
        return {
            status: null,
            form: {
                name: '',
                telephone: '',
                email: '',
                message: '',
                honey: '1Honey1Honey1',
                error: false,
            }
        };
    },
    validations() {
        return {
            form: {
                name: { 
                    required: helpers.withMessage("A name field is required.", required),
                },
                telephone: {
                    numeric: helpers.withMessage("Please enter a valid phone number", numeric)
                },
                email: {
                    required: helpers.withMessage("An email address field is required.", required),
                    email: helpers.withMessage("Please enter a valid email address", email)
                },
                message: {
                    required: helpers.withMessage("A message field is required. ", required),
                },
            }
        };
    },
    watch: {
        form: {
            handler(form){
                if (form.name || form.telephone || form.email || form.message) {
                    const sessionForm = { 
                        name: form.name,
                        telephone: form.telephone,
                        email: form.email,
                        message: form.message
                    }
                    sessionStorage.setItem('form', JSON.stringify(sessionForm));
                }
            },
            deep: true,
        }
    },
    methods: {
        async handleSubmit() {
            const isFormCorrect = await this.v$.$validate();
            if (isFormCorrect) {
                this.status = 'loading';
                
                const formData = new FormData();
                formData.append('name', this.form.name);
                formData.append('telephone', this.form.telephone);
                formData.append('email', this.form.email);
                formData.append('message', this.form.message);
                formData.append('honey', this.form.honey);

                try {
                    await axios({
                        method: "POST",
                        url: "https://everse.eu/kontakt.php",
                        data: formData,
                        headers: { "Content-Type": "multipart/form-data" }
                    });
                    this.status = 'success';
                    notify({ messages: [
                        'Thank you! Your message has been sent.',
                        'We will be in touch with you very shortly.'
                    ], type: 'success' })

                    this.v$.$reset();
                    this.clearForm();
                } catch (error) {
                    if (error) {
                        this.form.error =  'An error occurred, refresh the page and try again.'
                    }
                    this.status = 'failed';
                    notify({ messages: [
                        'There was an issue sending your message.',
                        'Please try again soon.'
                    ], type: 'error' })
                } 
            }
        },
        clearForm(){
            sessionStorage.removeItem('form');
            
            this.form.name = "";
            this.form.telephone = "";
            this.form.email = "";
            this.form.message = ""; 


            setTimeout(() => {
                this.status = null;
            }, 3000);
        },
    },
    mounted(){
        const form = sessionStorage.getItem('form');
        if (form !== null){
            const formObj = JSON.parse(form);
            this.form.name = formObj.name;
            this.form.telephone = formObj.telephone;
            this.form.email = formObj.email;
            this.form.message = formObj.message;

        }
    }
}
</script>