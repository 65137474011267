import {
    debounce
} from "lodash";

export default [{
        name: 'cutout',
        config: {
            mounted(el, binding) {
                el.classList.add('relative');

                const {
                    version = '1',
                        fill = 'fill-1',
                        mirror = [],
                        position = 'bottom'
                } = binding.value;

                const cutout = document.createElement('div');
                cutout.className = `cutout cutout--${version} cutout--${position}`;

                if (mirror && Array.isArray(mirror) && mirror.length) {
                    cutout.classList.add(...mirror.map(orientation => `cutout--flip--${orientation}`));
                }

                const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                svg.setAttribute('width', '100%');
                svg.setAttribute('height', '100%');
                svg.setAttribute('class', fill);
                svg.setAttribute('viewBox', '0 0 100 100');
                svg.setAttribute('preserveAspectRatio', 'none');

                const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                path.setAttribute('d', 'M0,0 Q50,15 100,100 100,100 100,100 0,100 0,100');
                path.setAttribute('vector-effect', 'non-scaling-stroke');

                svg.appendChild(path);
                cutout.appendChild(svg);
                const insertedEl = el.insertAdjacentElement(position === 'top' ? 'afterbegin' : 'beforeend', cutout);
                el.style.boxSizing = 'border-box';
                el.style.paddingBottom = insertedEl.getBoundingClientRect().height + 'px';
                el.setAttribute('data-cutout', insertedEl.getBoundingClientRect().height)


                const updateHeight = () => {
                    if (insertedEl) {
                        const newPadding = insertedEl.getBoundingClientRect().height;
                        el.style.paddingBottom = newPadding + 'px';
                    }
                };

                const debouncedUpdateHeight = debounce(updateHeight, 100);

                debouncedUpdateHeight();

                window.addEventListener('resize', debouncedUpdateHeight);

                el.__cutoutResizeListener__ = () => {
                    window.removeEventListener('resize', debouncedUpdateHeight);
                    debouncedUpdateHeight.cancel();
                };
            },
            unmounted(el) {
                if (el.__cutoutResizeListener__) {
                    el.__cutoutResizeListener__();
                }
            }
        }
    },
    {
        name: 'view',
        config: {
            mounted(el, binding) {
                let once = binding.arg && binding.arg.indexOf('once') !== -1;
                let cbAvail = binding.arg && binding.arg.indexOf('cb') !== -1;
                let cb = cbAvail ? binding.value : null
                const options = {
                    threshold: [0.1]
                }

                const observer = new IntersectionObserver((entries) => {
                    const entry = entries[0];

                    if (entry.isIntersecting) {
                        el.classList.add('inView');

                        if (cb){
                            cb(true);
                        } 
                        if (once) {
                            observer.unobserve(el);
                        }
                    } else {
                        el.classList.remove('inView');

                        if (cb){
                            cb(false);
                        }
                    }
                }, options);

                observer.observe(el);
            },
        }
    }
]