// notificationService.js
let currentNotification = null;
let listeners = [];

function notify(newNotification) {
  currentNotification = newNotification;
  listeners.forEach(listener => listener(currentNotification));
}

function onNotificationChange(callback) {
  listeners.push(callback);
  return () => {
    // This function will be used to unsubscribe the listener
    listeners = listeners.filter(listener => listener !== callback);
  };
}

export { notify, onNotificationChange };