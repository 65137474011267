<template>
    <section 
        id="about" 
        class="bg-1 color-0 pb-80 sm:pb-60" 
        data-nav="header--light"
    >
        <component is="Parallaxy" :speed="50" direction="opposite" class="container pb-36 sm:pb-16">
            <AnimatedHeader text="About Us." />
            <div class="relative z-10 mt-6">
                <div class="flex flex-row mt-6">
                    <div class="basis-3/4 sm:basis-full">
                        <h3 class="text-3 font-300">Welcome to Everse</h3>
                        <p class="body mt-12">Located in Poland, our team of adept English-speaking developers excels in Vue.js and JavaScript, offering exceptional web development services that propel your business forward. We cater to a diverse clientele across the US, UK, and EU, bringing a global perspective to every project. If you're seeking skilled developers for contract work or full-time collaboration, we're equipped to handle your needs with precision and innovation.</p>
                    </div>
                </div>
            </div>
            <div class="flex sm:flex-col">
                <div class="flex sm:flex-col gap-24 sm:gap-0 mt-24 sm:mt-12 basis-3/4 l:basis-full">
                    <div class="grow sm:basis-full flex">
                        <div class="image image--about">
                            <Parallaxy
                                :breakpoints="{ 0: { speed: 60 }, 1024: { speed: 100 } }"
                                :animation="(delta) => `transform: translate3d(${delta}%, 0, 0)`"
                                >
                                <img class="w-full" src="@/assets/content/images/image-1.png" alt="">
                            </Parallaxy>
                        </div>
                    </div>
                    <div class="basis-2/3 l:basis-1/2 sm:basis-full sm:order-last sm:mt-12">
                        <div>
                            <h4 class="font-400 mb-0 mt-0">Our Expertise</h4>
                            <p class="body mt-2">We build user-friendly, efficient websites and applications. Our team
                                is experienced in tackling both small and large-scale projects, ensuring your digital
                                needs are met with precision and simplicity.</p>
                        </div>						
                        <div class="mt-12">
                            <h4 class="font-400 mb-0">Your Business Goals Are Our Priority</h4>
                            <p class="body mt-2">We tailor our services to fit your unique business requirements.
                                Whether it's a short-term project or a long-term development plan, we adapt to your
                                needs to provide the right tech solutions.</p>
                        </div>
                        <div class="mt-12">
                            <h4 class="font-400 mb-0">Your Bridge to Digital Solutions</h4>
                            <p class="body mt-2">At eVerse, we make technology work for you. We're committed to
                                delivering functional and effective digital products that enhance your business.</p>
                        </div>
                        <div class="mt-12">
                            <h4 class="font-400 mb-0">Partnership for Progress</h4>
                            <p class="body mt-2">We align with your vision to forge a path towards digital excellence,
                                ensuring every step we take together is a step towards your business success.</p>
                        </div>
                    </div>
                </div>
            </div>
        </component>
    </section>
</template>
<script>
import AnimatedHeader from './AnimatedHeader.vue';
import Parallaxy from '@lucien144/vue3-parallaxy';
export default {
    name: 'About',
    components: {
        AnimatedHeader,
        Parallaxy
    }
};
</script>