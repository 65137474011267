<template>
	<main>
		<About />
		<Services />
		<Portfolio />
		<Contact />
	</main>
</template>

<script>
	import About from "../components/About.vue";
	import Portfolio from "../components/Portfolio.vue";
	import Services from "../components/Services.vue";
	import Contact from "../components/Contact.vue";


	export default {
		name: 'Home',
		components: {
			About,
			Portfolio,
			Services,
			Contact,
		},
	};
</script>