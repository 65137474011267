<template>
    <div 
        class="form__group" 
        :class="[`form__group--${component}`, { 'form__group--error': error.$error }]"
    >  
        <label 
            :for="id"
        >
            {{ label }}
            <span v-if="!required"> (optional) </span>
        </label>
        <div class="form__group--inner">
            <component
                :is="component"
                :id="id"
                :name="id" 
                :type="type" 
                :value="modelValue" 
                @keyup="$emit('update:modelValue', $event.target.value)"
                :cols="cols"
                :rows="rows"
            />
            <transition name="fade" appear mode="out-in">
                <GIcon v-if="error.$error" class="icon--error" name="error" />
            </transition>
        </div>
        <transition name="fade" appear mode="out-in">
            <p 
                v-if="error.$error" 
                class="form__error"
                v-text="error.$errors[0].$message" 
            />
        </transition>
    </div>
</template>
<script>
export default {
    name: 'FormInput',
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: String,
            default: "",
    },
        component: {
            type: String,
            default: 'input'
        },
        type: {
            type: String,
            default: 'text'
        },
        id: {
            type: [ String, Number ],
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        cols: {
            type: String,
            default: '30',
        },
        rows: {
            type: String,
            default: '10'
        },
        error: {
            type: Object,
            default: null
        },
        required: {
            type: Boolean,
            default: true,
        }
    },
};
</script>