<template>
    <div class="notification" :class="[
            notification?.type ?
            `notification--${notification?.type}`:
            null
        ]">
        <div class="notification__inner">
            <p 
                class="notification__message"
                v-for="(message, i) in notification?.messages"
                :key="i"
                v-text="message" 
            />
        </div>

        <div class="notification__progress">
            <div 
                class="notification__progress--line" 
                :style="progressBarStyle"
            ></div>
        </div>
    </div>
</template>

<script>
import { required } from '@vuelidate/validators';

    export default {
        name: 'Notification',
        props: {
            notification: {
                type: Object,
                default: {
                    messages: null,
                    type: null,
                }
            },
            duration: {
                type: Number,
                required: required,
            }
        },
        data() {
            return {
                progressBarScale: 0,
            };
        },
        computed: {
            progressBarStyle() {
                return {
                    transform: `scaleX(${this.progressBarScale})`
                };
            }
        },
        methods: {
            startCountdown() {
                const interval = 10;
                const totalUpdates = this.duration / interval;
                let currentUpdate = 0;

                this.countdownInterval = setInterval(() => {
                    currentUpdate++;
                    this.progressBarScale = currentUpdate / totalUpdates;

                    if (currentUpdate >= totalUpdates) {
                        clearInterval(this.countdownInterval);
                        this.visible = false;
                    }
                }, interval);
            }
        },
        mounted() {
            this.startCountdown();
        },
        beforeUnmount() {
            if (this.countdownInterval) {
                clearInterval(this.countdownInterval);
            }
        },
    }
</script>