<template>
    <section 
        id="portfolio" 
        data-nav="header--light" 
        class="bg-1 color-0 pb-80"
    >
        <component is="Parallaxy" :speed="50" direction="opposite" class="container pb-36 sm:pb-16">
            <div class="flex flex-row">
                <div class="basis-3/4 sm:basis-full">
                    <AnimatedHeader text="Portfolio." />
                    <div>
                        <h3 class="text-3 font-300">
                            Our portfolio showcases a breadth of expertise, offering a glimpse into the targeted solutions we've crafted for various industries.
                        </h3>
                    </div>
                    <div class="mt-12">
                        <div>
                            <h4 class="font-400 my-0">
                                Websites
                            </h4>
                            <p class="body mt-6">
                                We design and develop bespoke websites that emphasize user experience, accessibility, and security. Our approach ensures that every website serves its purpose effectively, whether to inform, engage, or facilitate services.
                            </p>
                        </div>
                        <div class="mt-12">
                            <h4 class="font-400 my-0">
                                Slide-based Presentations
                            </h4>
                            <p class="body mt-6">
                                Utilizing both established platforms and custom solutions, we create slide-based presentations that communicate complex information with clarity. Our presentations are designed to make an impact, whether they're used for educational, marketing, or corporate purposes.
                            </p>
                        </div>
                        <div class="mt-12">
                            <h4 class="font-400 my-0">
                                Interactive Training Modules
                            </h4>
                            <p class="body mt-6">
                                We produce interactive training modules that serve as powerful educational tools across industries. These modules are designed to be engaging and informative, enhancing the learning experience for diverse audiences.
                            </p>
                        </div>
                        <div class="mt-12">
                            <h4 class="font-400 my-0">
                                Event and Trade Show Applications
                            </h4>
                            <p class="body mt-6">
                                For events, conferences, and trade shows, we develop applications that capture attention and convey information interactively. Our applications are built to showcase products, services, and ideas in a way that's both informative and engaging.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Lightbox />
        </component>
    </section>
</template>
<script>
import AnimatedHeader from './AnimatedHeader.vue';
import Lightbox from './Lightbox.vue';
import Parallaxy from "@lucien144/vue3-parallaxy"
export default {

    name: 'Portfolio',
    components: {
        AnimatedHeader,
        Lightbox,
        Parallaxy
    },
};
</script>